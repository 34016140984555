<template>
  <div class="absolute top-0 z-50  w-full h-full flex items-center justify-center">
    
    <div class=" w-full h-full bg-gray-500 blur-lg opacity-70"></div>

    <div class=" orderPanel  absolute flex flex-col m-auto w-10/12 md:w-4/5 md:max-w-sm rounded bg-slate-200">
      <div>
        <div class="flex border-b border-blue-400 p-2">
          <div class="ml-auto font-bold text-xl">Create Community</div>
          <div v-on:click="close()" class=" ml-auto w-8 h-8 bg-slate-100 border-slate-300 rounded-full flex active:scale-75 hover:scale-105">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" w-7 h-7 m-auto">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
          </div>
        </div>
      </div>
      

      <div class=" md:h-auto" >
        
        <div class=" flex flex-col p-6">
           <input class=" m-2 md:m-4" type="text" v-model="communityname" placeholder="Enter Community Name" />
           <input class=" m-2 md:m-4" type="text" disabled v-model="country" placeholder="Enter Country" />
           <input class=" m-2 md:m-4" type="text" disabled v-model="province" placeholder="Enter Province" />
           <input class=" m-2 md:m-4" type="text" disabled v-model="city" placeholder="Enter City" />
           <input class=" m-2 md:m-4" type="text" v-model="area" placeholder="Enter Area" />
           <input class=" m-2 md:m-4" type="text" v-model="locationcoordinates" placeholder="Enter Location Coordinates" />
           <input class=" m-2 md:m-4" type="number" v-model="coverage" placeholder="Enter Coverage" />
           <input class=" m-2 md:m-4" type="number" v-model="bandwidth" placeholder="Enter Community Bandwidth" />
           <!-- <input class=" m-2 md:m-4" type="number" v-model="status" placeholder="Enter Status" /> -->
           <select v-model="status" class=" w-full h-10 border mb-4 ">
                  <option  v-for="(communityStatus, index) in CommunityStatuses" v-bind:key="index">{{communityStatus}}</option>
                  <option :value="this.status" disabled hidden>Community Status</option>
            </select>
           <input class=" m-2 md:m-4" type="number" v-model="running_costs" placeholder="Enter Running Costs" />

           <p v-if="errors.length">
            <b>Please correct the following error(s):</b>
              <ul class=" text-red-500">
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </p>
          
           <button 
            class=" bg-gray-200 font-bold text-gray-600 cursor-pointer shadow p-2 mx-auto" 
            v-on:click="createCommunity()"> 
            Create
          </button>
          
        </div>
      </div>
    
    
    </div>

  
  </div>
</template>

<script>
// @ is an alias to /src

import axios from "axios";
export default {
  name: 'SingUpView',
  components: {
    // signUpPage: SignUpPage,
  },
  data () {
       return{
        communityname:'',
        country:'Zambia',
        province:'Copperbelt',
        city:'Ndola',
        area:'',
        locationcoordinates:'',
        coverage:'',
        bandwidth:'',
        status:'',
        running_costs:'',

        CommunityStatuses:["NOT ACTIVE","PENDING", "ACTIVE"],
        errors: [],
        BackendUrl: "https://labsapi.packymstechnology.com"
       }
      },
  methods:{
   async createCommunity(){
    this.checkForm();
     if(this.errors.length){
       // this.warnShakeError()
        console.log("Still have an Error")
      }
      else{
        this.$parent.showLoading(true)
        let dev = process.env.VUE_APP_DEVELOPMENT;    
        if (dev == "true") {
          console.log("is in dev mode")
          this.BackendUrl = "";
        }
        try {
          let result = await axios.post(this.BackendUrl + "/admin/community", {
          communityname:this.communityname,
          country:this.country,
          province:this.province,
          city:this.city,
          area: this.area,
          locationcoordinates:this.locationcoordinates,
          coverage:Number(this.coverage),
          status:this.status,
          bandwidth:Number(this.bandwidth),
          running_costs:Number(this.running_costs),
        });

        console.log(result)

        if(result.status == 200){
          // alert("Signed up ready to login")
          this.$parent.setMessageBox("Created new community", "accepted")
          this.close();
          //bring up login page
        } else {
          alert("there was an error")
          
          
        }
        } catch (error) {
          const code = error.response.status
          if(code == 403){
            this.$parent.setMessageBox("Username or email already taken", "warning")
          } else{
            this.$parent.setMessageBox("Something went wrong, Please check your internet connection or try again later", "error")
          }
        }
        
        this.$parent.showLoading(false)
        }
    
    },
    checkForm(){
            this.errors = [];
            if (!this.communityname) {
              this.errors.push("community name required.");
            }
            if (!this.country) {
              this.errors.push("country required.");
            }
            if (!this.province) {
              this.errors.push("province required.");
            }
            if (!this.city) {
              this.errors.push('city required.');
            }
            if (!this.area) {
              this.errors.push("area name required.");
            }
            if (!this.locationcoordinates) {
              this.errors.push("location coordinates required.");
            }
            if (!this.coverage) {
              this.errors.push("coverage required.");
            }
            if (!this.status) {
              this.errors.push("Community status required.");
            }
            if (!this.bandwidth) {
              this.errors.push("bandwidth required.");
            }
           
        },
        validEmail(email){
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
    logIn(){
      this.$parent.showLogin(true);
      this.$parent.showSignUp(false);
    },
    close(){
      this.$parent.showCreateCommunityPanel(false);
    }

  }
  
}
</script>

<style scoped>

.panel{
  max-height: 80%;
}

</style>

<template>
  <div class="absolute top-0 z-50  w-full h-full flex items-center justify-center">
    
    <div class=" w-full h-full bg-gray-500 blur-lg opacity-70"></div>

    <div class=" orderPanel  absolute flex flex-col m-auto w-10/12 md:w-4/5 md:max-w-md rounded bg-slate-200">
      <div>
        <div class="flex border-b border-blue-400 p-2">
          <div class="ml-auto font-bold text-xl">All Internet Subs</div>
          <div v-on:click="close()" class=" ml-auto w-8 h-8 bg-slate-100 border-slate-300 rounded-full flex active:scale-75 hover:scale-105">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" w-7 h-7 m-auto">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
          </div>
        </div>
        <div class="flex pl-2">
          <button v-on:click="changeTab('all')" class=" p-2 bg-white text-gray-600 font-medium cursor-pointer shadow px-2 py-1 rounded-b-md border-x-white border-b-white border-x border-b" >All</button>
          <button v-on:click="changeTab('active')" class=" ml-3 p-2 bg-white text-gray-600 font-medium cursor-pointer shadow px-2 py-1 rounded-b-md border-x-white border-b-white border-x border-b" >Active</button>
          <button v-on:click="changeTab('notActive')" class=" ml-3 p-2 bg-white text-gray-600 font-medium cursor-pointer shadow px-2 py-1 rounded-b-md border-x-white border-b-white border-x border-b" >Not Active</button>
        </div>
      </div>
      
      
      
      <div v-if="showAllSubs" class=" flex flex-col overflow-y-auto mt-2 w-full text-sm">
        <div v-for="(data,i) in data" :key="i" class=" w-full p-4">
          <div class="flex flex-col w-full h-full rounded bg-gray-100 p-4">
          <div class=" flex flex-col h-fit w-full">
            <div class=""> Subscription Package: {{ data.data_plan }} </div>  
          </div>

          <div class="mt-2 flex flex-row">
            <div class=""> UserName: {{ data.username }} </div>  
          </div>

          <div class="mt-2 flex flex-row">
            <div class=" ">Order Number: {{ data.id }}</div>
            <div class=" font-medium ml-auto">K {{ data.amount }}</div>            
          </div>

          <div class="mt-2 flex flex-row text-sm font">
            <div class=" flex flex-col">
              <div class=" ">Subscription Status: </div>
              <div class=" font-medium">{{ data.subscription_status }}</div>

              <div>
                <div>
                  <div class=" mt-2 ">Order Date: </div>
                  <div class=" font-medium">{{ convertDate(data.created_at) }}</div> 
                </div>
                <div>
                  <div class=" mt-2 ">Expiring Date: </div>
                  <div class=" font-medium">{{ convertDate(data.sub_expiry_date) }}</div> 
                </div>
                 
              </div> 
            </div>
            <div class=" flex flex-col ml-auto">
              <div class=" ">Payment Status: </div>
              <div class=" font-medium ml-auto">{{ data.payment_status }}</div>  

              <button v-if="data.payment_status == 'FAILED'" v-on:click="showPayment(data.amount, data.reference_id)" class=" bg-yellow-200  text-gray-600 font-medium cursor-pointer shadow px-2 py-1 ml-auto"> Pay Now</button>
              <!-- <button v-else-if="data.payment_status == 'PENDING'" v-on:click="showPayment(data.amount, data.reference_id)" class=" bg-green-200  text-gray-600 font-medium cursor-pointer shadow px-2 py-1 ml-auto"> Sync Payment</button> -->
            
              <button v-on:click="showPayment(data.amount, data.reference_id)" class="  bg-white mt-3  text-gray-600 font-medium cursor-pointer shadow px-2 py-1 ml-auto"> Print Recipt</button>
            
            </div>
            
          </div>
            
          </div>         
          
        </div>

      </div>

      <div v-else-if="showActiveSubs" class=" flex flex-col overflow-y-auto mt-2 w-full text-sm">
        <div v-for="(data,i) in ActiveSubs" :key="i" class=" w-full p-4">
          <div class="flex flex-col w-full h-full rounded bg-gray-100 p-4">
          <div class=" flex flex-col h-fit w-full">
            <div class=""> Subscription Package: {{ data.data_plan }} </div>  
          </div>

          <div class="mt-2 flex flex-row">
            <div class=""> UserName: {{ data.username }} </div>  
          </div>

          <div class="mt-2 flex flex-row">
            <div class=" ">Order Number: {{ data.id }}</div>
            <div class=" font-medium ml-auto">K {{ data.amount }}</div>            
          </div>

          <div class="mt-2 flex flex-row text-sm font">
            <div class=" flex flex-col">
              <div class=" ">Subscription Status: </div>
              <div class=" font-medium">{{ data.subscription_status }}</div>

              <div>
                <div>
                  <div class=" mt-2 ">Order Date: </div>
                  <div class=" font-medium">{{ convertDate(data.created_at) }}</div> 
                </div>
                <div>
                  <div class=" mt-2 ">Expiring Date: </div>
                  <div class=" font-medium">{{ convertDate(data.sub_expiry_date) }}</div> 
                </div>
                 
              </div> 
            </div>
            <div class=" flex flex-col ml-auto">
              <div class=" ">Payment Status: </div>
              <div class=" font-medium ml-auto">{{ data.payment_status }}</div>  

              <button v-if="data.payment_status == 'FAILED'" v-on:click="showPayment(data.amount, data.reference_id)" class=" bg-yellow-200  text-gray-600 font-medium cursor-pointer shadow px-2 py-1 ml-auto"> Pay Now</button>
              <!-- <button v-else-if="data.payment_status == 'PENDING'" v-on:click="showPayment(data.amount, data.reference_id)" class=" bg-green-200  text-gray-600 font-medium cursor-pointer shadow px-2 py-1 ml-auto"> Sync Payment</button> -->
            
              <button v-on:click="showPayment(data.amount, data.reference_id)" class="  bg-white mt-3  text-gray-600 font-medium cursor-pointer shadow px-2 py-1 ml-auto"> Print Recipt</button>
            
            </div>
            
          </div>
            
          </div>         
          
        </div>

      </div>

      <div v-else-if="showNotActiveSubs" class=" flex flex-col overflow-y-auto mt-2 w-full text-sm">
        <div v-for="(data,i) in NotActiveSubs" :key="i" class=" w-full p-4">
          <div class="flex flex-col w-full h-full rounded bg-gray-100 p-4">
          <div class=" flex flex-col h-fit w-full">
            <div class=""> Subscription Package: {{ data.data_plan }} </div>  
          </div>

          <div class="mt-2 flex flex-row">
            <div class=""> UserName: {{ data.username }} </div>  
          </div>

          <div class="mt-2 flex flex-row">
            <div class=" ">Order Number: {{ data.id }}</div>
            <div class=" font-medium ml-auto">K {{ data.amount }}</div>            
          </div>

          <div class="mt-2 flex flex-row text-sm font">
            <div class=" flex flex-col">
              <div class=" ">Subscription Status: </div>
              <div class=" font-medium">{{ data.subscription_status }}</div>

              <div>
                <div>
                  <div class=" mt-2 ">Order Date: </div>
                  <div class=" font-medium">{{ convertDate(data.created_at) }}</div> 
                </div>
                <div>
                  <div class=" mt-2 ">Expiring Date: </div>
                  <div class=" font-medium">{{ convertDate(data.sub_expiry_date) }}</div> 
                </div>
                 
              </div> 
            </div>
            <div class=" flex flex-col ml-auto">
              <div class=" ">Payment Status: </div>
              <div class=" font-medium ml-auto">{{ data.payment_status }}</div>  

              <button v-if="data.payment_status == 'FAILED'" v-on:click="showPayment(data.amount, data.reference_id)" class=" bg-yellow-200  text-gray-600 font-medium cursor-pointer shadow px-2 py-1 ml-auto"> Pay Now</button>
              <!-- <button v-else-if="data.payment_status == 'PENDING'" v-on:click="showPayment(data.amount, data.reference_id)" class=" bg-green-200  text-gray-600 font-medium cursor-pointer shadow px-2 py-1 ml-auto"> Sync Payment</button> -->
            
              <button v-on:click="showPayment(data.amount, data.reference_id)" class="  bg-white mt-3  text-gray-600 font-medium cursor-pointer shadow px-2 py-1 ml-auto"> Print Recipt</button>
            
            </div>
            
          </div>
            
          </div>         
          
        </div>

      </div>
    
    </div>

  

    <div v-if="previewCase" class=" absolute top-0 z-40  w-full h-full flex items-center justify-center">
    
    <div class=" w-full h-full bg-gray-500 blur-lg opacity-70"></div>
    <div class=" absolute flex flex-col m-auto text-xl font-bold w-10/12 md:w-96 rounded bg-white p-6">
      
      
      <img v-if="ImageData" alt="logo" class="" :src=ImageData>

        <button
                class=" mt-4 self-center bg-gray-200 border-gray-300 border font-medium text-gray-600 cursor-pointer shadow py-2 px-4 mx-auto"
                @click="showPreview(false)"
              >
                Close
        </button>
        <!-- <button
                class=" mt-4 self-center bg-gray-200 border-gray-300 border font-medium text-gray-600 cursor-pointer shadow py-2 px-4 mx-auto"
                @click="ShowPayerMsgBox(false)"
              >
                Save Image
        </button> -->

      </div>
      
    </div>

    <setOrderStatus v-if="showSetOrderStatus"></setOrderStatus>

    <div class="hidden absolute top-0 z-50  w-full h-full flex items-center justify-center bg-white">
      <div class=" border-2 w-full md:w-4/5  p-10">
        <div class=" flex justify-center">
          <img alt="logo" class=" h-12 mr-5" src="../../assets/logo.png">
          <div class=" flex flex-col">
            <div class=" text-xl">PACKYMS TECHNOLOGY</div>
            <div class=" self-center">Community Internet</div>
          </div>
          
        </div>
        <div>
          <div class=" mt-5">
            <div>
              To: <b>Username</b> 
            </div>
            <!-- <div class=" mt-2">
              Printed Date: 24/02/2024
            </div> -->
            <div>
              Address: <b>Ndola</b>
            </div>
          </div>

          <div class=" mt-10">
            <div>
              Subscription Package: <b>Starter</b>
            </div>
            <div>
              Amount: <b>K500</b>
            </div>
            <div class=" mt-2">
              Reference ID: <b>s-yk-2</b>
            </div>
            <!-- <div class="mt-2">
              Created Date: <b>24/02/2024</b>
            </div> -->
            <div class=" mt-2">
              Expiration Date: <b>24/02/2024</b>
            </div>

            <div class=" border-t-2 mt-6">
              <div class=" text-center"> Thank you for supporting this community project </div>
            </div>
            
          </div>

        </div>

      </div>
    </div>
  
  </div>
  
</template>

<script>
// @ is an alias to /src

import axios from "axios";
import { mapGetters, mapMutations,mapActions } from "vuex";
import SetOrderStatus from "@/views/admin/SetOrderStatusView.vue";

export default {
  name: 'OrderPage',
  components: {
    setOrderStatus: SetOrderStatus,
  },
  data () {
       return{
        details:'',
        amount:'',
        status:'',
        order_date:'',
        data:[],
        ActiveSubs:[],
        NotActiveSubs:[],
        showAllSubs:true,
        showActiveSubs:false,
        showNotActiveSubs:false,
        pageNumber: 1,
        PageSize: 100,
        previewCase: false,
        ImageData:null,
        selectedOrder:null,
        selectedOrderIndex:null,
        showSetOrderStatus: false,
        BackendUrl: "https://labsapi.packymstechnology.com"
       }
      },

      computed: {
      ...mapGetters(["GET_SCREENSHOT"]),

    },
  methods:{
    ...mapMutations([
        "REGENERATE_GEOMETRY", 
        "LOADED_STL",
        "SET_COLOR",
        "SET_SCREENSHOT_POSITION"      
      ]),
      ...mapActions(["REGENERATE_GEOMETRY","LOAD_STL","TAKE_SCREENSHOT"]),
    
    updateAndClose(valueData){
      this.data[this.selectedOrderIndex].order_status = valueData;
      this.showUpdateOrderStatus(false);
    },    
    close(){
      this.$parent.showAllInternetSubs(false);
    },
    showPreview(show,img){
      if(show){
      //   this.SET_SCREENSHOT_POSITION()
      // this.$parent.showLoading(true)
      // this.TAKE_SCREENSHOT().then(() => {
      //       setTimeout(()=>{
      //         // this.LOADED_STL()
      //         this.ImageData = this.GET_SCREENSHOT
      //         this.previewCase = true
      //         this.$parent.showLoading(false)
      //       },1000)
      //     })
        this.previewCase = true
        this.ImageData = img
      }else{
        this.previewCase = false
      }
      
    },
    showPayment(amount, ref){
      this.$parent.showPayment(true, amount, ref);
    },
    async loadOrders(){
      this.$parent.showLoading(true)
      let dev = process.env.VUE_APP_DEVELOPMENT;    
    if (dev == "true") {
      console.log("is in dev mode")
      this.BackendUrl = "";
    }
         
      try {
        let result = await axios.get(this.BackendUrl + "/admin/all_internet_subs", {
            headers:{
            Authorization : `Bearer ${JSON.parse(localStorage.getItem('account')).access_token}`
          }
          });
          if(result.status == 200){
            // alert(" Order fetched")
            this.data = result.data
            this.sortSubs();
            // this.close();
  
          } else {
            alert("there was an error with placing Order")
          }
      } catch (error) {
        const code = error.response.status
        
          if(code == 401){
            this.close();
            this.$parent.setMessageBox("Please LogIn Again", "warning")
            this.$parent.showLogin(true);
          } else{
            this.$parent.setMessageBox("Something went wrong, Please check your internet connection or try again later", "error")
          }
      }
     
  
          
          this.$parent.showLoading(false)
    },

    GetColorFromDetails(details){
      let options = details.split(",");
      let color = options[1].trim();
      return color
    },

    SetPreview(details, path){
      let dev = process.env.VUE_APP_DEVELOPMENT;  
      if (dev == "true") {
      console.log("is in dev mode")
      this.BackendUrl = "http://localhost:8080";
      path = this.BackendUrl + path.substring(1)
    }
      let color = this.GetColorFromDetails(details)
      this.SET_COLOR(color);
      this.LOAD_STL(path).then(() => {
            setTimeout(()=>{
              // this.LOADED_STL()
              this.$parent.showLoading(false)
              this.close()
            },1000)
          // this.applyChanges();
          });
    },
    showUpdateOrderStatus(show){
      this.showSetOrderStatus = show;
    },
    ChangeStatus(id, index){
      this.selectedOrder = id;
      this.selectedOrderIndex = index;
      this.showUpdateOrderStatus(true);
    },
    setMessageBox(message,type){
      this.$parent.setMessageBox(message, type)
    },
    convertDate(date){
      const formatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'short' });
      return formatter.format(Date.parse(date))
    },

    changeTab(tab){
      if(tab == "all"){
        this.showAllSubs = true;
        this.showActiveSubs = false;
        this.showNotActiveSubs = false;
      } else if(tab == "active"){
        this.showAllSubs = false;
        this.showActiveSubs = true;
        this.showNotActiveSubs = false;
      } else if(tab == "notActive"){
        this.showAllSubs = false;
        this.showActiveSubs = false;
        this.showNotActiveSubs = true;
      }
    },
    sortSubs(){
      console.log("loading owing")
      for (let index = 0; index < this.data.length; index++) {
        if(this.data[index].is_active){
          this.ActiveSubs.push(this.data[index])
        } else{
          this.NotActiveSubs.push(this.data[index])
        }
               
      }
      console.log(this.owingusers)
    }

  },
  mounted(){
    let user = localStorage.getItem('account');
    if(user) {
      this.loadOrders()
    }
  }
  
}
</script>
<style scoped>
.orderPanel{
max-height: 85%;
}
</style>

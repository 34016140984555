<template>
  <div class="absolute top-0 z-50  w-full h-full flex items-center justify-center">
    
    <div class=" w-full h-full bg-gray-500 blur-lg opacity-70"></div>

    <div class=" orderPanel  absolute flex flex-col m-auto w-10/12 md:w-4/5 md:max-w-md rounded bg-slate-200">
      <div>
        <div class="flex border-b border-blue-400 p-2">
          <div class="ml-auto font-bold text-xl">Costs And Income {{ data.length - 1 }}</div>
          <div v-on:click="close()" class=" ml-auto w-8 h-8 bg-slate-100 border-slate-300 rounded-full flex active:scale-75 hover:scale-105">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" w-7 h-7 m-auto">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
          </div>
        </div>
      </div>
      
      
      <div class=" mx-auto mt-2">
        
        <div class=" flex space-x-5">

          <div class=" mt-2 flex flex-col items-center text-center">
            <div class=" w-6 h-6 bg-slate-100 border-slate-300 rounded-full">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205 3 1m1.5.5-1.5-.5M6.75 7.364V3h-3v18m3-13.636 10.5-3.819" />
              </svg>
            </div>
            <div>{{ data.length - 1 }} Communities</div>
          </div>

          <div class=" mt-2 flex flex-col items-center text-center">
            <div class=" w-6 h-6 bg-slate-100 border-slate-300 rounded-full">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
              </svg>
            </div>
            <div>{{ subData.length }} Subs</div>
          </div>

        </div>

        <div class=" h-fit flex">
          <div class=" text-sm p-1">
              <div class=" flex items-center">
                  <div class=" h-3 w-3 mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
                    </svg>

                  </div>
                  <div>Costs: {{ totalCost }}</div>
              </div>
              <div class=" flex items-center">
                  <div class=" h-3 w-3 mr-1 ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9 9V4.5M9 9H4.5M9 9 3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5 5.25 5.25" />
                    </svg>

                  </div>
                  <div> Income: {{ totalIncome }}</div>
              </div>
          </div>
          <!-- <pieChart :income="totalCost" :costs="totalIncome"></pieChart> -->
          <div class=" p-1 text-sm rounded text-center">
            <div>Profit/Loss:</div>
            <div>K{{ totalIncome - totalCost }}</div>
          </div>
          
          
        </div>


        <!-- <div>
          <div> Total Communities: {{ data.length - 1 }} </div>
          <div> Active Subscriptions: {{ subData.length }} </div>
        </div>
        <div class=" mt-3 mb-1">
          <div>Communities</div>
          <div>costs: K {{ totalCost }}</div>
          <div>income: K {{ totalIncome }}</div>
          <div>total profit/loss: K {{ totalIncome - totalCost }}</div>
        </div> -->

      </div>
      
      <div  class=" flex flex-col overflow-y-auto mt-2 w-full text-sm">
        <div v-for="(data,i) in data" :key="i" class=" w-full p-4">
          <div v-if="data[1] && data[0].id != 0" class="flex flex-col w-full h-full rounded bg-gray-100 p-4">
            <div class=" flex flex-col h-fit w-full text-center">
              <div class=" text-lg"> {{ data[0].community_name }} </div>  
            </div>
            <div class=" flex w-full h-auto">
              <div class=" flex flex-col items-center h-fit w-1/2">
                <div>
                    <div class=" flex items-center">
                        <div class=" h-3 w-3 mr-1 bg-red-400"></div>
                        <div>Costs: {{ data[0].running_costs }}</div>
                    </div>
                    <div class=" flex items-center">
                        <div class=" h-3 w-3 mr-1 bg-green-500"></div>
                        <div> Income: {{ data[1].CommunityIncome }}</div>
                    </div>
                </div>
                <pieChart :income="data[1].CommunityIncome" :costs="data[0].running_costs"></pieChart>
                <div>Profit/Loss: K{{ data[1].CommunityIncome - data[0].running_costs }} </div>

                <div class=" w-full mt-3 items-center flex flex-col">
                  <div class=" h-5 w-11/12 border-gray-500 border rounded-2xl flex">
                    <div v-if="data[1].CommunityCapacity>95" class="h-full w-full bg-red-400 rounded-2xl text-sm text-center">
                      <span class=" h-3">{{ parseFloat(data[1].CommunityCapacity).toFixed(1) }}% </span> 
                    </div>
                    <div v-else-if="data[1].CommunityCapacity<95 && data[1].CommunityCapacity>75" class="h-full bg-orange-300 rounded-2xl text-sm text-center" :style="{ 'width': data[1].CommunityCapacity+'%'}">
                      <span class=" h-3">{{ parseFloat(data[1].CommunityCapacity).toFixed(1) }}% </span> 
                    </div>
                    <div v-else class="h-full w-full bg-green-400 rounded-2xl text-sm text-center" :style="{ 'width': data[1].CommunityCapacity+'%'}">
                      <span class=" h-3">{{ parseFloat(data[1].CommunityCapacity).toFixed(1) }}% </span> 
                    </div>
                  </div>
                  <div>Capacity</div>
                </div>
              </div>
              <div class=" flex flex-col items-center w-1/2">
                
                <div class=" mt-2 flex flex-col items-center text-center">
                  <div class=" w-6 h-6 bg-slate-100 border-slate-300 rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                    </svg>
                  </div>
                  <div>{{ data[1].totalUsers }} Users</div>
                </div>

                <button v-on:click="showUserListButton(data[1].userList)" class="  bg-white mt-3  text-gray-600 font-medium cursor-pointer shadow px-2 py-1"> List Users </button>
                
                <div class="flex flex-col items-center">
                  <div class=" mt-2 flex flex-col items-center text-center">
                    <div class=" w-6 h-6 bg-slate-100 border-slate-300 rounded-full">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.348 14.652a3.75 3.75 0 0 1 0-5.304m5.304 0a3.75 3.75 0 0 1 0 5.304m-7.425 2.121a6.75 6.75 0 0 1 0-9.546m9.546 0a6.75 6.75 0 0 1 0 9.546M5.106 18.894c-3.808-3.807-3.808-9.98 0-13.788m13.788 0c3.808 3.807 3.808 9.98 0 13.788M12 12h.008v.008H12V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                      </svg>
                    </div>
                    <div>{{ data[0].coverage }}M</div>
                  </div>
                  
                  

                  <div class="mt-2 flex flex-col items-center text-center">
                    <div class=" w-6 h-6 bg-slate-100 border-slate-300 rounded-full">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                      </svg>                 
                    </div>
                    <div>{{ data[0].city }},</div>
                    <div>{{ data[0].area }},</div>
                  </div>                 

                </div>
              </div>
              
            </div>
          <!-- <div class=" flex flex-col h-fit w-full">
            <div class=""> Community Name: {{ data[0].community_name }} </div>  
          </div>
          
          <div class="mt-2 flex flex-row text-sm font">
            <div class=" flex flex-col">
              <div class=" ">City: {{ data[0].city }}</div>
              
              <div>
                <div>
                  <div class=" mt-2 ">Area: {{ data[0].area }}</div>
                </div>
                <div>
                  <div class=" mt-2 ">Coverage: {{ data[0].coverage }} Meters</div>
                </div>
                <div>
                  <div class=" mt-2 ">Users: {{ data[1].totalUsers }} </div>
                </div>
                <div>
                  <div class=" mt-2 ">Community Income: K{{ data[1].CommunityIncome }} </div>
                </div>
                <div>
                  <div class=" mt-2 ">Running Costs: K{{ data[0].running_costs }}</div>
                </div>
                <div>
                  <div class=" mt-2 ">Running Capacity: {{ data[1].CommunityCapacity }}</div>
                </div>
                <div>
                  <div class=" mt-2 ">profit/loss: {{ data[1].CommunityIncome - data[0].running_costs }}</div>
                </div>
                <div>
                  <div class=" mt-2 ">Created at: {{ convertDate(data[0].created_at) }}</div>
                </div>
                 
              </div> 
            </div>
            <div class=" flex flex-col ml-auto">            
              <button v-on:click="showUserListButton(data[1].userList)" class="  bg-white mt-3  text-gray-600 font-medium cursor-pointer shadow px-2 py-1 ml-auto"> List Users </button>
            
            </div>
            
          </div> -->
            
          </div>         
          
        </div>

      </div>
    
    </div>

  

    <div v-if="showUserList" class="absolute top-0 z-50  w-full h-full flex items-center justify-center">
    
    <div class=" w-full h-full bg-gray-500 blur-lg opacity-70"></div>

    <div class=" orderPanel  absolute flex flex-col m-auto w-10/12 md:w-4/5 md:max-w-md rounded bg-slate-200">
      <div class="flex border-b border-blue-400 p-2">
        <div class="ml-auto font-bold text-xl">Community Members {{ userList.length }}</div>
        <div v-on:click="showUserListBox(false)" class=" ml-auto w-8 h-8 bg-slate-100 border-slate-300 rounded-full flex active:scale-75 hover:scale-105">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" w-7 h-7 m-auto">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
        </div>
      </div>
      
      
      <div class=" flex flex-col overflow-y-auto mt-2 w-full text-sm">
        <div v-for="(user,i) in userList" :key="i" class=" w-full p-4">
          <div class="flex flex-col w-full h-full rounded bg-gray-100 p-4">
            
          <div class="mt-2 flex flex-row">
            <div class=" ">User Name: {{ user }}</div>
          </div>
            
        </div>         
          
        </div>

      </div>
    
    </div>
  
  </div>
  

    <createCommunity v-if="showCreateCommunity"></createCommunity>

    <setOrderStatus v-if="showSetOrderStatus"></setOrderStatus>
  
  </div>
  
</template>

<script>
// @ is an alias to /src

import axios from "axios";
import { mapGetters, mapMutations,mapActions } from "vuex";
import SetOrderStatus from "@/views/admin/SetOrderStatusView.vue";
import CreateCommunity from "@/views/admin/CreateCommunity.vue";
import PieChart from "@/components/PieChart.vue";

export default {
  name: 'OrderPage',
  components: {
    setOrderStatus: SetOrderStatus,
    createCommunity: CreateCommunity,
    pieChart:PieChart,
  },
  data () {
       return{
        totalCost:0,
        totalIncome:0,
        details:'',
        amount:'',
        status:'',
        order_date:'',
        data:[],
        subData:[],
        userList:[],
        pageNumber: 1,
        PageSize: 100,
        previewCase: false,
        ImageData:null,
        selectedOrder:null,
        selectedOrderIndex:null,
        showSetOrderStatus: false,
        showCreateCommunity: false,
        showUserList: false,
        BackendUrl: "https://labsapi.packymstechnology.com"
       }
      },

      computed: {
      ...mapGetters(["GET_SCREENSHOT"]),
      
    },
  methods:{
    ...mapMutations([
        "REGENERATE_GEOMETRY", 
        "LOADED_STL",
        "SET_COLOR",
        "SET_SCREENSHOT_POSITION"      
      ]),
      ...mapActions(["REGENERATE_GEOMETRY","LOAD_STL","TAKE_SCREENSHOT"]),
    
    updateAndClose(valueData){
      this.data[this.selectedOrderIndex].order_status = valueData;
      this.showUpdateOrderStatus(false);
    },   
    openEditInfo(){
      this.$parent.showUpdateProfile(true);
      this.$parent.showProfile(false);
    }, 
    close(){
      this.$parent.showCostIncome(false);
    },
    showPreview(show,img){
      if(show){
      //   this.SET_SCREENSHOT_POSITION()
      // this.$parent.showLoading(true)
      // this.TAKE_SCREENSHOT().then(() => {
      //       setTimeout(()=>{
      //         // this.LOADED_STL()
      //         this.ImageData = this.GET_SCREENSHOT
      //         this.previewCase = true
      //         this.$parent.showLoading(false)
      //       },1000)
      //     })
        this.previewCase = true
        this.ImageData = img
      }else{
        this.previewCase = false
      }
      
    },
    showPayment(amount, ref){
      this.$parent.showPayment(true, amount, ref);
    },

    getSubBandwidth(sub){
      var b = 0;
      if(sub == "recommended" || sub == "supporter"){
        b = 6.5;
      } else if(sub == "starter"){
        b = 4;
      } else{
        b=0;
      }

      return b;

    },

    CalculateCapacity(usersBandwidth, communityBandwidth ){
      console.log("User Bandwidth:")
      console.log(usersBandwidth)
      console.log("Community Bandwidth:")
      console.log(communityBandwidth)
      return (usersBandwidth*100)/communityBandwidth
    },
    
    async loadCommunities(){
      this.$parent.showLoading(true)
      let dev = process.env.VUE_APP_DEVELOPMENT;    
    if (dev == "true") {
      console.log("is in dev mode")
      this.BackendUrl = "";
    }
         
      try {
        let result = await axios.get(this.BackendUrl + "/admin/communities", {
            headers:{
            Authorization : `Bearer ${JSON.parse(localStorage.getItem('account')).access_token}`
          }
          });
          if(result.status == 200){
            // alert(" Order fetched")
            this.data = result.data
            // this.close();
            this.getTotalCosts();
            this.loadActiveInternetSubs();
          } else {
            alert("there was an error with placing Order")
          }
      } catch (error) {
        const code = error.response.status
        
          if(code == 401){
            this.close();
            this.$parent.setMessageBox("Please LogIn Again", "warning")
            this.$parent.showLogin(true);
          } else{
            this.$parent.setMessageBox("Something went wrong, Please check your internet connection or try again later", "error")
          }
      }
     
  
          
          this.$parent.showLoading(false)
    },

    async loadActiveInternetSubs(){
      this.$parent.showLoading(true)
      let dev = process.env.VUE_APP_DEVELOPMENT;    
    if (dev == "true") {
      console.log("is in dev mode")
      this.BackendUrl = "";
    }
         
      try {
        let result = await axios.get(this.BackendUrl + "/admin/active_internet_subs", {
            headers:{
            Authorization : `Bearer ${JSON.parse(localStorage.getItem('account')).access_token}`
          }
          });
          if(result.status == 200){
            // alert(" Order fetched")
            this.subData = result.data
            // this.close();
            this.getTotalIncome();
            this.addUsersToCommunity();
          } else {
            alert("there was an error with placing Order")
          }
      } catch (error) {
        const code = error.response.status
        
          if(code == 401){
            this.close();
            this.$parent.setMessageBox("Please LogIn Again", "warning")
            this.$parent.showLogin(true);
          } else{
            this.$parent.setMessageBox("Something went wrong, Please check your internet connection or try again later", "error")
          }
      }
     
  
          
          this.$parent.showLoading(false)
    },

    GetColorFromDetails(details){
      let options = details.split(",");
      let color = options[1].trim();
      return color
    },

    SetPreview(details, path){
      let dev = process.env.VUE_APP_DEVELOPMENT;  
      if (dev == "true") {
      console.log("is in dev mode")
      this.BackendUrl = "http://localhost:8080";
      path = this.BackendUrl + path.substring(1)
    }
      let color = this.GetColorFromDetails(details)
      this.SET_COLOR(color);
      this.LOAD_STL(path).then(() => {
            setTimeout(()=>{
              // this.LOADED_STL()
              this.$parent.showLoading(false)
              this.close()
            },1000)
          // this.applyChanges();
          });
    },
    showLoading(show){
      this.$parent.showLoading(show)
    },
    showUserListBox(show){
      this.showUserList = show;
    },
    showUserListButton(users){
      this.userList = users;
      this.showUserListBox(true)
    },
    showUpdateOrderStatus(show){
      this.showSetOrderStatus = show;
    },
    showCreateCommunityPanel(show){
      this.showCreateCommunity = show;
    },
    ChangeStatus(id, index){
      this.selectedOrder = id;
      this.selectedOrderIndex = index;
      this.showUpdateOrderStatus(true);
    },
    setMessageBox(message,type){
      this.$parent.setMessageBox(message, type)
    },
    convertDate(date){
      const formatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'short' });
      return formatter.format(Date.parse(date))
    },

    getTotalCosts(){
      var total = 0;

      for (let index = 0; index < this.data.length; index++) {
        total = total + this.data[index].running_costs;
        
      }

      this.totalCost = total;
      // console.log("totalCost:")
      // console.log(this.totalCost)

    },

    getTotalIncome(){
      var total = 0;

      for (let index = 0; index < this.subData.length; index++) {
        total = total + this.subData[index].internet_subscription.amount;
    
      }

      this.totalIncome = total;
      // console.log("total Income:")
      // console.log(this.totalIncome)

    },

    addUsersToCommunity(){
      
      
      for (let x = 0; x < this.data.length; x++) {
        let users = {totalUsers:0, usersBandwidth:0, CommunityCapacity:0 , CommunityIncome: 0, userList:[]}
        let tempdata = [];
        tempdata.push(this.data[x])
        tempdata.push(users)
        this.data[x] = tempdata
        // console.log(this.data[x])
        
        
        // this.data.push(users)
        for (let y = 0; y < this.subData.length; y++) {
          // let tempUsers = []
          if(this.data[x][0].id == this.subData[y].from_community_id){
            this.data[x][1].userList.push(this.subData[y].internet_subscription.username)
            // this.data[x][1].userList = tempUsers
            this.data[x][1].totalUsers = this.data[x][1].totalUsers+1

            this.data[x][1].usersBandwidth = this.data[x][1].usersBandwidth+this.getSubBandwidth(this.subData[y].internet_subscription.data_plan)

            this.data[x][1].CommunityIncome = this.data[x][1].CommunityIncome + this.subData[y].internet_subscription.amount
            // console.log(this.data[x][1].userList)
          }
          
        }

        this.data[x][1].CommunityCapacity = this.CalculateCapacity(this.data[x][1].usersBandwidth, this.data[x][0].current_bandwidth);
        
      }
      
    }

  },
  mounted(){
    let user = localStorage.getItem('account');
    if(user) {
      this.loadCommunities();
      

    }
  }
  
}
</script>
<style scoped>
.orderPanel{
max-height: 85%;
}
</style>
